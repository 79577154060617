<script>
import { mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import appConfig from "../../../app.config";

import {
  authMethods,
  notificationMethods,
} from "@/state/helpers";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      tryingToLogIn: false,
      passwordInputType: 'password'
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Username or Email is required", required),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    ...mapState("auth", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    loading() {
      return this.$store.state.globals.loading;
    }
  },
  mounted() {
     this.$store.dispatch('notification/clear', null, { root: true });
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }
      const { username, password } = this;
      if (username && password) {
        this.$store.dispatch('auth/login', { username, password });
        this.password = '';
        this.v$.$reset();
      }
    },
    showPassword() {
      if (this.passwordInputType === 'password') {
        return this.passwordInputType = 'text';
      }
      return this.passwordInputType = 'password';
    }
  },
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img
                    src="@/assets/images/qpax/logo.png"
                    alt=""
                    width="200"
                  />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Traffic Citation Management System
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

       
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
              <b-overlay :show="loading">
              <div class="card mt-4">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">Welcome Back !</h5>
                    <p class="text-muted">Sign in to continue to TCMS.</p>
                  </div>
                  <div class="p-2 mt-4">
                    <b-alert v-if="notification.message" 
                      show :variant="notification.type" class="fade show" role="alert">
                        {{ notification.message}}
                    </b-alert>

                    
                      <form @submit.prevent="tryToLogIn">
                        <div class="mb-3">
                          <label for="username" class="form-label">Username or Email</label>
                          <input
                            autofocus
                            class="form-control"
                            id="username"
                            placeholder="Enter username or email..."
                            v-model="username"
                            :class="{
                              'is-invalid': submitted && v$.username.$error,
                            }"
                          />
                          <div
                            v-for="(item, index) in v$.username.$errors"
                            :key="index"
                            class="invalid-feedback"
                          >
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>

                        <div class="mb-3">
                          <div class="float-end">
                            <router-link
                              to="/forgot-password"
                              class="text-muted"
                              >Forgot password?</router-link
                            >
                          </div>
                          <label class="form-label" for="password-input"
                            >Password</label
                          >
                          <div class="position-relative auth-pass-inputgroup mb-3">
                            <input
                              :type="passwordInputType"
                              v-model="password"
                              class="form-control pe-5"
                              :class="{
                                'is-invalid': submitted && v$.password.$error,
                              }"
                              placeholder="Enter password"
                              id="password-input"
                            />
                            <button
                              class="
                                btn btn-link
                                position-absolute
                                end-0
                                top-0
                                text-decoration-none text-muted
                              "
                              type="button"
                              id="password-addon"
                            >
                              <i @click="showPassword" class="ri-eye-fill align-middle"></i>
                            </button>
                            <div
                              v-if="submitted && v$.password.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="v$.password.required.$message">{{
                                v$.password.required.$message
                              }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="mt-4">
                          <button class="btn btn-success w-100" type="submit">
                            Sign In
                          </button>
                        </div>
                      </form>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
              </b-overlay>
            </div>
          </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} QPAX Traffic Systems, Inc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>